import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box } from '@mui/system';

export default function WovenImageList({ muted }: {muted:boolean}) {
  return (
    <ImageList sx={{ width: '100%', height: '450px' }} variant="woven" cols={3} gap={8}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          {item.notImg ? (
            <div>{item.a ? (<div><video src={`${item.img}?w=161&fit=crop&auto=format`} muted={muted} controls playsInline width="161px" autoPlay loop /><Box height="10px"/><video src={`${item.a}?w=161&fit=crop&auto=format`} muted={muted} width="161px" controls autoPlay playsInline loop /></div>):(<video src={`${item.img}?w=161&fit=crop&auto=format`} width="161px" muted={muted} controls autoPlay playsInline loop />)}</div>) : (
          <img
            src={`${item.img}?w=161&fit=crop&auto=format`}
            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />)}
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/media/buli.jpg',
    title: 'Buliii',
  },
  {
    img: '/media/copiselfi.jpg',
    title: 'Szelfii',
  },
  {
    img: '/media/kopi.jpg',
    title: 'Kopihágaa',
  },
  {
    img: '/media/nyhvn.JPEG',
    title: 'Nyhvnnnn',
  },
  {
    img: '/media/sushi.JPEG',
    title: 'Sushiii',
  },
  {
    img: '/media/tihanyLookSq.jpg',
    title: 'Tihanyiii',
  },
  {
    img: '/media/cs.mp4',
    title: 'Beach vidii',
    notImg: true,
    a: '/media/seta.mp4',
  },
  {
    img: '/media/nyhvn.mp4',
    title: 'Nyhvn vidii',
    notImg: true,
  },
  {
    img: '/media/beach.mp4',
    title: 'Kopibuli',
    notImg: true,
  },
];
