import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    header: {
      height: string;
    };
    footer: {
      height: string;
    };
    input: {
      height: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    header?: {
      height?: string;
    };
    footer?: {
      height?: string;
    };
    input?: {
      height?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFB3C1',
    },
    secondary: {
      main: '#40AFE8',
    },
    error: {
      main: '#b01f15',
    },
  },
  header: {
    height: '64px',
  },
  footer: {
    height: '48px',
  },
  input: {
    height: '48px',
  },
});

export default theme;