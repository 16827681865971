import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const links = [
  {u:'https://player.vimeo.com/video/609264396?h=df0145b720&color=c9ff23&title=0&byline=0&portrait=0',t:'Opera és tenger + dili futóverseny',d:'Vii tenger + szép hely + mi + szerelem. Ez jó kombináció volt, de lehet ide se mennék ilyen boldogan rossz és szeles időben'},
  /* {u:'https://drive.google.com/file/d/1cMuGsEBYgNLE73JHVwCjhCMEkSJbq8f7/preview',t:'Opera és tenger',d:'Vii tenger + szép hely'}, */
  /* {u:'https://drive.google.com/file/d/1xZUDy1JthGtb-SYDnhqCEOavHuuFvmve/preview',t:'Kongens Have',d:'Szerelmemmel sétáltunk egy jót a királyok parkjában'}, */
  {u:'https://player.vimeo.com/video/609262135?h=f021b49ae2&color=c9ff23&title=0&byline=0&portrait=0',t:'Kongens Have - nagyon szexi park',d:'Szerelmemmel sétáltunk egy jót a királyok parkjában. Az idő nagyon exkluzív volt, erre mondják azt "ha van tökéletes"..'},
  {u:'https://player.vimeo.com/video/609262973?h=b0896eca40&color=c9ff23&title=0&byline=0&portrait=0',t:'Torvehallerne - a fancy piac sok emberrel',d:'Elmentünk a piacra, jól nézett ki szerintem! Talán következőre már be is ülünk kávézni? Csak meg kell küzdeni a sorral :)'},
  /* {u:'https://drive.google.com/file/d/1pCgjk5QAwf5f5W9Wm1udRkgVVBWBxDpT/preview',t:'Torvehallerne',d:'Elmentünk a piacra, jól nézett ki szerintem!'}, */
  /* {u:'https://drive.google.com/file/d/1AcAjwDM-JWuJ1WGGePxfxbqnNJx9GtSu/preview',t:'Burger fesztivál',d:'Láttuk milyen drága egy Burger hihi'}, */
  {u:'https://player.vimeo.com/video/609252510?h=3484043e4c&color=c9ff23&title=0&byline=0&portrait=0',t:'Burger fesztivál',d:'Láttuk milyen drága egy Burger hihi'},
];

// <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>


export default function ActionAreaCard({ i, b }: {i: 0 | 1 | 2 | 3, b?: boolean}) {
  return (
    <Card sx={{ maxWidth: 345, mt: b ? 0 : 3 }}>
      <CardActionArea>
        <CardMedia
          component="iframe"
          allowFullScreen
          src={links[i].u}
          width="500"
          height="200"
          // preload
          // alt="green iguana"
          //  controls="controls" preload="none"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          {links[i].t}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {links[i].d}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
