import { Box, Button, LinearProgress, Switch, TextField, Typography } from "@mui/material";

import DiscreteSliderMarks from "./Slider";
import MvText from "./MvText";
import LinearWithValueLabel from "./Result";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export default function Game({ appstate, onNext, onReset }: { appstate: number; onNext: () => void; onReset: () => void }) {
  const fakeLoading = () => {
    setTimeout(() => {
      onNext();
    }, 22000);
  }
  const renderGame = () => {
    switch(appstate) {
      case 0: return (
        <Button
          variant="contained"
          color="primary"
          sx={{ color: "#A4133C" }}
          onClick={() => onNext()}
        >
          Start
        </Button>
      );
      case 1: return (
        <Box width="100%">
          <Typography sx={{ pt: 1, fontSize: 20, fontWeight: 100 }}>Mennyire szeretsz?</Typography>
          <DiscreteSliderMarks />
          <Box pt={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#A4133C" }}
            onClick={() => onNext()}
          >
            Következő
          </Button>
          </Box>
        </Box>
      );
      case 2: return (
        <Box width="100%">
          <Typography sx={{ py: 1, fontSize: 20, fontWeight: 100 }}>Jellemezd egy szóval a szerelmed:</Typography>
          <TextField id="outlined-basic" label="Szerelmed jellemzője" variant="outlined" fullWidth />
          <Box pt={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#A4133C" }}
            onClick={() => onNext()}
          >
            Következő
          </Button>
          </Box>
        </Box>
      );
      case 3: return (
        <Box width="100%">
          <Box pb={2}>
          <Typography sx={{ py: 1, fontSize: 20, fontWeight: 100, display: 'inline' }}>Elfogadom, hogy <i>Biró Vince</i> kezelje a válaszaim:</Typography>
          <Switch { ... { inputProps: { 'aria-label': 'Switch demo' }} } defaultChecked />
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#A4133C" }}
            onClick={() => { onNext(); fakeLoading(); }}
          >
            Kalkulálás
          </Button>
        </Box>
      );
      case 4: return (
        <Box width="100%">
          <Box>
            <LinearProgress />
            <MvText />
          </Box>
        </Box>
      );
      case 5: return (
        <Box width="100%">
          <Typography sx={{ pt: 1, fontSize: 20, fontWeight: 100 }}>Az eredmények (szerelmi szintben):</Typography>
          <Box p={3}>
            <Typography sx={{ pt: 1, fontSize: 20, fontWeight: 100 }}>Emese:</Typography>
            <LinearWithValueLabel num={94+getRandomInt(5)} />
            <Typography sx={{ pt: 1, fontSize: 20, fontWeight: 100 }}>Vince:</Typography>
            <LinearWithValueLabel num={100}/>
          </Box>
          <Box pt={4}>
            <Button
                variant="contained"
                color="primary"
                sx={{ color: "#A4133C" }}
                onClick={() => onReset()}
            >
                Újrakezdés
            </Button>
          </Box>
        </Box>
      );
      default: return 'oops';
    }
  };

  return <Box py={1} height="300px" display="flex" alignItems="center" justifyContent="center" >{renderGame()}</Box>;
}
