import { useEffect } from 'react';
import { init } from 'ityped';
import { Box } from '@mui/system';

export default function MvText() {
  useEffect(() => {
    const typedBox = document.querySelector('#typed');
    const container = document.createElement('div');
    container.className = 'typedContainer';
    const typedDiv = document.createElement('div');
    typedDiv.className = 'typed';
    if (typedBox) {
      typedBox?.appendChild(container);
      container?.appendChild(typedDiv);
      init(typedDiv, {
        showCursor: true,
        strings: ['Kalkulálás...', 'Tudományos tényezők figyelembe vétele...', 'Tudósok álláspontjának betöltése...', 'Pontos számítás elvégzése...'],
        typeSpeed: 100,
        backSpeed: 30,
        startDelay: 500,
        backDelay: 1600,
      });
    }
    return () => container.remove();
  });

  return (
    <Box id="typed" sx={{
        mt: 1,
        textAlign: 'center',
        '& .ityped-cursor': {
            fontSize: 25,
            opacity: 1,
            animation: 'blink 0.3s infinite',
            animationDirection: 'alternate',
            color: (theme) => theme.palette.primary.main,
            display: 'inline',
        },
        '& .typed': {
            fontSize: 25,
            color: (theme) => theme.palette.primary.main,
            display: 'inline',
        },
      }} />
  );
};
