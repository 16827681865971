import { ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/system";

export default function Section({ children }: { children: ReactNode }) {
  const [hh, setHh] = useState<number>();
  useEffect(()=>{setHh(window.innerHeight)}, []);
  
  return (
    <Box position="relative" height={hh ? `${hh}px` : "100vh"} p={0} m={0}>{children}</Box>
  );
}