import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({ num }: { num: number }) {
  const [progress, setProgress] = React.useState(5);

  React.useEffect(() => {
    let timer: number | undefined | NodeJS.Timeout;
    timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= num) {
          clearInterval(timer as number);
          return prevProgress;
        }
        return prevProgress + 5;
      });
    }, 400);
    return () => {
      clearInterval(timer as number);
    };
  }, [num]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
