import { useState } from 'react';
import { Button, Typography } from "@mui/material";
import { Box, ThemeProvider } from "@mui/system";
import CakeIcon from '@mui/icons-material/Cake';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Link, Element } from 'react-scroll';
import Divider from '@mui/material/Divider';

import Timeline from "./Timeline";
import Section from './Section';
import LineBg from "./LineBg.svg";
import LineBgFlip from "./Untitled.svg";
import DoubleLine from "./doubleLine.svg";
import Arrow from "./Untitled2.svg";
import theme from "./theme";
import CustomImageList from "./ImageList";
import ActionAreaCard from "./Card";
import WovenImageList from "./Imgs"; 
import Game from './Game';

export default function App() {
  const [muted,setMuted] = useState(true);
  const [appstate, setAppstate] = useState(0);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        color: "#A4133C",
        fontFamily: "'Montserrat', sans-serif",
        background: `url(${LineBgFlip}) right top no-repeat, url(${LineBg}) right bottom no-repeat`,
      }}>
        <Element name="up">
          <Section>
            <Typography
              component="h1"
              sx={{ fontSize: 54, p: '16px', fontWeight: 900, lineHeight: 1.4 }}
            >
              Szia <Box component="span" sx={{
                background: 'repeating-linear-gradient(45deg,#FF4D6D,#FF4D6D 30px,#A4133C 30px,#A4133C 60px)',
                backgroundClip: 'text',
                color: 'transparent',
                WebkitBackgroundClip: 'text',
                animation: '40s linear 0s infinite move',
              }}>Szerelmem</Box>
            </Typography>
            <Box px="16px">
            <Typography sx={{ display: 'inline',fontSize: 42, fontWeight: 600 }}>Üdvözöllek a születésnapi weboldaladon</Typography>
            <Box
              component={CakeIcon}
              sx={{ color: '#A4133C', fontSize: 42, verticalAlign: 'sub' }}
            />
            </Box>
            <Box sx={{
              position: 'absolute',
              left: '16px',
              fontSize: '20px',
              top: 'calc(100% - 133px - 30px)',
            }}>
              A kezdéshez lépj tovább
            </Box>
            <Box sx={{
              position: 'absolute',
              left: 'calc(100% - 153px - 160px)',
              bottom: 0,
              width: '153px',
              height: '133px',
              background: `url(${Arrow}) right top no-repeat`,
              backgroundSize: 'contain',
            }} />
            <Link to="sec1" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="sec1">
          <Section>
            <Box p="16px" pb="0px">
              <Typography sx={{ display: 'inline',fontSize: 36, fontWeight: 100 }}>A virtuális szülinapod
               a következőkből fog állni:</Typography>
            </Box>
            <Timeline />
            <Box sx={{
                position: 'absolute',
                height: '200px',
                width: '100%',
                left: 0,
                bottom: '-100px',
                background: `url(${DoubleLine}) right bottom no-repeat`,
              }}
            />
            <Link to="sec2a" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="sec2a">
          <Section>
            <Box p="16px">
              <Typography sx={{ display: 'inline',fontSize: 36, fontWeight: 600 }}>Élménytenger szerelmemmel</Typography><Box
                component={FavoriteBorderIcon}
                sx={{ color: '#A4133C', fontSize: 36, verticalAlign: 'sub' }}
              />
              <CustomImageList />
            </Box>
            <Link to="secb3" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
                onClick={(e) => {e.preventDefault(); setMuted(false);}}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="secb3">
          <Section>
            <Box p="16px">
              <Typography sx={{ display: 'inline',fontSize: 32, fontWeight: 600 }}>Még több szerelem!</Typography>
              <WovenImageList muted={muted} />
            </Box>
            <Link to="seca4" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
                onClick={(e) => {e.preventDefault(); setMuted(true);}}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="seca4">
          <Section>
            <Box p="16px">
              <Typography sx={{ fontSize: 28, fontWeight: 600 }}>Worldwide szerelem</Typography>
              <Typography sx={{ fontSize: 24, fontWeight: 100 }}>GoPro videók következnek!</Typography>
              <ActionAreaCard i={0} b />
            </Box>
            <Link to="sc5" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="sc5">
          <Section>
            <Box p="16px">
              <Typography sx={{ display: 'inline',fontSize: 32, fontWeight: 600 }}>Hamarosan újra átéljük ezeket &#128525;</Typography>
              <ActionAreaCard i={1} />
            </Box>
            <Box sx={{
                position: 'absolute',
                height: '200px',
                width: '100%',
                left: 0,
                bottom: '-70px',
                background: `url(${DoubleLine}) right bottom no-repeat`,
              }}
            />
            <Link to="game" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="game">
          <Section>
            <Box p="16px">
              <Typography sx={{ fontSize: 30, fontWeight: 600 }}>Mini játék:</Typography>
              <Typography sx={{ pb: 1, fontSize: 36, fontWeight: 100 }}>Kalkulátor</Typography>
              <Divider />
              <Game appstate={appstate} onNext={() => setAppstate((old) => old + 1)} onReset={() => setAppstate(0)} />
            </Box>
            <Link to="bonus" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="bonus">
          <Section>
            <Box p="16px">
            <Typography
              component="h1"
              sx={{ fontSize: 30, p: '8px', fontWeight: 600, lineHeight: 1.4 }}
            >
               <Box component="span" sx={{
                background: 'repeating-linear-gradient(45deg,#FF4D6D,#FF4D6D 30px,#A4133C 30px,#A4133C 60px)',
                backgroundClip: 'text',
                color: 'transparent',
                WebkitBackgroundClip: 'text',
                animation: '40s linear 0s infinite move',
              }}>Isten Éltessen Picim</Box>!
            </Typography>
              <ActionAreaCard i={2} />
            </Box>
            <Link to="end" spy={true} smooth={true} duration={500}>
              <Button
                variant="contained"
                endIcon={<ArrowDownwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Tovább
              </Button>
            </Link>
          </Section>
        </Element>
        <Element name="end">
          <Section>
            <Box p="16px">
              <Typography sx={{ display: 'inline',fontSize: 34, fontWeight: 600 }}>Bónusz</Typography>
              <ActionAreaCard i={3} b />
              <Typography sx={{ fontSize: 16, fontWeight: 100, pt: 1 }}>Remélem jól érezted magad! Kicsit nehéz volt a tervezés, mivel nem tudtam iPhoneról fogod-e nézni ezt már vagy a régi telefonodról. Azért próbáltam mindent tökéletesen megjeleníteni az Androidos telefonodon is &#x1f497;</Typography>
            </Box>
            <Link to="up" spy={true} smooth={false} duration={1000}>
              <Button
                variant="contained"
                endIcon={<ArrowUpwardIcon />}
                color="primary"
                sx={{ color: "#A4133C", position: 'absolute', bottom: '24px', right: '24px' }}
              >
                Az elejére
              </Button>
            </Link>
          </Section>
        </Element>
      </Box>
    </ThemeProvider>
  );
}
